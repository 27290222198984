import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/privateRoute"
import { LogBookSummary } from "../../components/log-book-summary"

const App = () => (
    <Router>
        <PrivateRoute path="/app/log-book/summary" component={LogBookSummary} />
    </Router>
)

export default App