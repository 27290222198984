import React from "react"
import { getAccessToken } from "../services/auth"
import { backAuthServerUrl, frontClientUrl, authClientId } from "../services/constant";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
	console.log("check route");
	if (!getAccessToken()) {
		if (location.pathname !== "undefined" || location.pathname !== null) {
			localStorage.setItem('stateRedirectURL', location.pathname);
		}
		window.location.href = backAuthServerUrl() + "/oauth/authorize?response_type=code&client_id=" + authClientId() + "&redirect_uri=" + frontClientUrl() + "&scope=read";
		return null
	}

	return <Component {...rest} />
}

export default PrivateRoute