import React from "react";
import { afterLogoutPage, getAccessToken, logout } from "../services/auth";
import { backAuthAPI, backAuthServerUrl, frontClientUrl } from "../services/constant";
import Layout from "./layout";
import Seo from "./seo";

class ButtonLoadingAnimation extends React.Component {
    render() {
        return (
            <div className="flex items-center justify-center mt-2">
                <button type="button"
                    className="inline-flex items-center px-3 py-2 border border-transparent text-xs font-semibold
                                            font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    disabled="">
                    <svg className="w-4 h-4 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>
                    Loading...
                </button>
            </div>
        );
    }
}

export class LogBookSummary extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        deleteButtonLoading: false
    }

    deleteAccount = () => {
        const url = backAuthAPI() + "/profile/delete"
        let token = "Bearer " + getAccessToken();
        this.setState({ deleteButtonLoading: true });
        fetch(url, {
            method: "DELETE", // default, so we can ignore
            headers: {
                'Content-Type': 'application/json',
                'Accept': "application/json",
                'Authorization': token
            }
        })
            .then((res) => {
                this.setState({ deleteButtonLoading: false });
                if (!res.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${res.status}`
                    );
                }
                return res.json();
            })
            .then(result => {
                console.log(result);
                this.setState({ deleteButtonLoading: false });
                if (result.success && result.code === 200) {
                    logout(true);
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => {
                this.setState({ deleteButtonLoading: false });
            })


    }

    render = () => {
        return (
            <Layout>
                <Seo title="Log Book Summary" />
                <div className="overflow-hidden lg:relative">
                    <div className="pt-4 sm:pt-4 lg:py-4 bg-gray-50">
                        <div className="max-w-7xl mx-auto">
                            <div className="px-4 sm:px-12 sm:text-center max-w-4xl mx-auto lg:items-center">
                                <div>
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                                        <span className="text-green-500">Coming soon private</span>...
                                    </h1>
                                    {
                                        !this.state.deleteButtonLoading && <button onClick={this.deleteAccount}
                                            type="button"
                                            className="inline-flex items-center px-2.5 py-2 border border-transparent text-xs font-semibold
                                        font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        >
                                            Delete Account
                                        </button>
                                    }
                                    {this.state.deleteButtonLoading && <ButtonLoadingAnimation />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    };
}


